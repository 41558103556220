<template> 
  <div v-html="body"></div>
</template>

<script>
export default {
  name: 'PageBody',
  props: {
    body: {type: String},
    prev: {type: String},
    next: {type: String},
  }
}
</script>

<style scoped></style>
