<template>
  <PageBody v-if="body"
    :body="body"
    :prev="prev"
    :next="next"
  />
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import PageBody from '../components/PageBody.vue';

export default {
  name: 'Home',
  components: {PageBody},
  data(){
    return{
      body: null,
      prev: null,
      next: null,      
      canScroll: true,
      interval: 500
    }
  },
  methods:{			
			...mapActions({
				vuex_fetch_pageData: 'fetch_pageData',
        vuex_clear_pageData: 'clear_pageData',
			}),

      onScroll(e){
        if(!this.canScroll){return;}
        if((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight){
            if(this.next !== null){
              if(this.$route.path !== this.next){

                this.canScroll = false;
                let gotoUrl = this.next;

                this.$router.push({path: this.next});
                this.vuex_clear_pageData();                                                

                setTimeout(() => { 
                  // console.log(`Нужны данные для ${gotoUrl}`);
                  this.vuex_fetch_pageData(gotoUrl);
                }, this.interval);                                
              }
            }else{
              console.log('this.next = null. Дальше некуда сколлить');
            }           
            // console.log(e.target.scrollTop, window.top.scrollY);                       
        }
        if((window.top.scrollY === 0) && (this.prev !== null)){              
          this.canScroll = false;
          let gotoUrl = this.prev;

          this.$router.push({path: this.prev});
          this.vuex_clear_pageData();

          setTimeout(() => {
            // console.log(`Нужны данные для ${gotoUrl}`);
            this.vuex_fetch_pageData(gotoUrl);
          }, this.interval);
        }
      }
  },
  computed:{
    ...mapGetters({
      vuex_get_pageData: 'get_pageData'
    }),
  },  
  created(){    
    this.home_unsubscribe = this.$store.subscribe(async (mutation, state) => {
				// console.log(mutation);
				if(typeof(mutation.payload) !== 'undefined'){          
          if(mutation.type === 'SET_NEXT'){
            let {title, description, body, prev, next } = this.vuex_get_pageData;
               
            // console.log('Получили', {title, description, body, prev, next });           

            document.title = title
            document.querySelector('head meta[name="description"]')
                .setAttribute('content', description);;

            this.body = body;
            this.prev = prev;
            this.next = next;

            if(this.body !== ''){this.canScroll = true;}
          }
        }
    });    
    this.vuex_fetch_pageData(this.$route.path);
  },
   mounted(){
      window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy(){
    this.home_unsubscribe();
    window.removeEventListener("scroll", this.onScroll);
    console.log('Компонет будет уничтожен');
  }
}
</script>
