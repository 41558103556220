import Vue from 'vue'
import Vuex from 'vuex'

import axios from '../api/';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: null,
    description: null,
    body: null,
    prev: null,
    next: null,    
  },
  getters:{
    get_pageData: (state) => ({              
        title: state.title,
        description: state.description,
        body: state.body,
        prev: state.prev,
        next: state.next,
    })
  },
  mutations: {
    SET_TITLE(state, data) { state.title = data },
    SET_DESCRIPTION(state, data) { state.description = data},
    SET_BODY(state, data) { state.body = data},
    SET_PREV(state, data) { state.prev = data},
    SET_NEXT(state, data) { state.next = data},
  },
  actions: {
    clear_pageData({ commit }){
      commit('SET_TITLE', '');
      commit('SET_DESCRIPTION', '');
      commit('SET_BODY', '');
      commit('SET_PREV', '');
      commit('SET_NEXT', '');
    },

    async fetch_pageData({ commit, state }, url){
      try{
        if(!url.startsWith('/')){url = `/${url}`;}

        let resp = await axios.post('api', {url}),
          {data} = resp;

        commit('SET_TITLE', data.title);
        commit('SET_DESCRIPTION', data.description);
        commit('SET_BODY', data.body);
        commit('SET_PREV', data.prev);
        commit('SET_NEXT', data.next);

      }catch(err){
        console.error(err.message);
      }

    }
  },
})
